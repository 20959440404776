import classNames from "classnames";
import { useEffect, useState } from "react";

import { MainButton } from "../ui-components/MainButton";
import styles from "./MiniPopup.module.scss";

interface MiniPopupProps {
  children: React.ReactNode;
  shown: boolean;
  onClose?(): void;
  btnText?: string;
  iconClass?: string;
  closeIcon?: boolean;
  onCloseIcon?(): void;
  extraClass?: string;
  dialogClass?: string
}

const MiniPopup = ({
  children,
  shown = true,
  onClose = () => undefined,
  btnText = "",
  iconClass = "",
  closeIcon = false,
  onCloseIcon = () => undefined,
  extraClass = "",
  dialogClass = ""
}: MiniPopupProps) => {
  const [shownLocal, setShownLocal] = useState(shown);

  useEffect(() => {
    if (shown === shownLocal) return;
    setShownLocal(shown);
  }, [shown]);

  if ((!children && !iconClass) || !shownLocal) return null;

  const handleClose = () => {
    setShownLocal(false);
    onClose();
  };

  const handleCloseIconClick = () => {
    setShownLocal(false);
    onCloseIcon();
  };

  return (
    <div className={classNames(styles.root, extraClass)}>
      <div className={classNames(styles.dialog, dialogClass)}>
        {iconClass && (
          <div className={styles.icon}>
            <i className={classNames("svg-icon", iconClass)} />
          </div>
        )}
        <div className={styles.content}>{children}</div>
        {btnText && (
          <div className={styles.buttonContainer}>
            <MainButton className={styles.buttonConfirm} onClick={handleClose}>
              {btnText}
            </MainButton>
          </div>
        )}
        {closeIcon && (
          <button className={styles.cross} onClick={handleCloseIconClick}>
            <i className={classNames("svg-icon", "svg-icon_delete")} />
          </button>
        )}
      </div>
    </div>
  );
};

export default MiniPopup;
